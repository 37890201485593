import React, { useEffect, useState } from "react";
import config from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function Modall({ handleClose }) {
  const [menuType, setMenuType] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [insertCategories, setInsertCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState("");
  const [dishName, setDishName] = useState("");
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");

  const handleSave = async () => {
    if (!subCategorySelected || !dishName || !menuType || !selectedVendor) {
      toast("Please fill in all fields");
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/Insert_Outsource_Menu_Details`,
        {
          Sub_category_id: subCategorySelected,
          Menu_Name: dishName,
          Menu_Type: menuType,
          outsource_vendor_id: selectedVendor,
        }
      );
  
      if (response.status === 200) {
        toast("Menu details inserted successfully");
        setDishName("");
        setMenuType("");
        setCategorySelected("");
        setSubCategorySelected("");
        setSelectedVendor("");
      } else {
        toast("Failed to insert menu details");
      }
    } catch (error) {
      console.error("Error inserting menu details:", error);
      toast("An error occurred while saving the dish");
    }
  };
  


  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_fullmenu_subcatagorizes_list`)
      .then((response) => {
        setInsertCategories(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
        toast.error("Error fetching categories, please try again later.");
      });
  }, []);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setCategorySelected(categoryId);

    const selectedCat = insertCategories.find(
      (cat) => cat.category_id === parseInt(categoryId)
    );
    setSelectedSubCategory(selectedCat ? selectedCat.sub_categories : []);
    setSubCategorySelected("");
  };

  const handleSubCategoryChange = (e) => {
    setSubCategorySelected(e.target.value);
  };

  const handleDishNameChange = (e) => {
    setDishName(e.target.value);
  };
  const handleMenuTypeChange = (e) => {
    setMenuType(e.target.value);
  };




  useEffect(() => {
    const fetchVendors = async () => {
        try {
            const response = await axios.get(`${config.apiBaseUrl}/vendors/Get_Outsource_Vendors`);
            if (response.status === 200) {
                setVendors(response.data.vendors);
                console.log(response.data.vendors)
            } else {
                throw new Error('Failed to fetch vendors');
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    fetchVendors();
}, []);

const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
};
  return (
    <>
      <ToastContainer />
      <div
        className="modal bd-example-modal-xl"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block", zIndex: 1050 }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="p-3">
              <h5 className="mb-0"> Add Oursource vendor </h5>
            </div>
            <div className="dishdivchoose">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <h6 className="catagris">Menu Type</h6>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineRadio1"
                      name="categoryType"
                      value="veg"
                      checked={menuType === "veg"}
                      onChange={handleMenuTypeChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Veg
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineRadio2"
                      name="categoryType"
                      value="non-veg"
                      checked={menuType === "non-veg"}
                      onChange={handleMenuTypeChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Non-veg
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
                  <h6 className="catagris">Main categories</h6>
                  <select
                    className="form-select"
                    value={categorySelected}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      Choose Main categories
                    </option>
                    {insertCategories.map((category) => (
                      <option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4  col-md-4 col-sm-12 col-12">
                  <h6 className="catagris">Sub categories</h6>
                  <select
                    className="form-select"
                    value={subCategorySelected}
                    onChange={handleSubCategoryChange}
                  >
                    <option value="" disabled>
                      Choose Sub categories
                    </option>
                    {selectedSubCategory.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div className=" col-lg-5 col-md-6 col-sm-12">
                  <h6 className="catagris mt-4">choose outsource vendor</h6>
                  <select
                    className="form-select"
                    value={selectedVendor}
                    onChange={handleVendorChange}
                >
                    <option value="">Choose Outsource Vendor</option>
                    {vendors.map((vendor) => (
                        <option key={vendor.Vendor_id} value={vendor.Vendor_id}>
                            {vendor.Vendor_Name}
                        </option>
                    ))}
                </select>
                </div>
                <div className=" col-lg-7 col-md-6 col-sm-12 col-12">
                  <h6 className="catagris mt-4">Enter Dish Name</h6>
                  <input
                    type="text"
                    value={dishName}
                    placeholder="Enter dish name"
                    className="form-group-input"
                    onChange={handleDishNameChange}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className=" closecatary-savebtn"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleSave}
                className=" catary-savebtn"
              >
                Save 
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        onClick={handleClose}
        style={{ zIndex: 1040 }}
      ></div>
    </>
  );
}

export default Modall;
