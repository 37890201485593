import React, { useState, useEffect } from "react";
import "../style/orders.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";

export const Leads = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/dashboard/Get_Leads`);
        setEvents(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchEvents();
  }, []);

  const ordersPerPage = 10;
  const totalPages = Math.ceil(events.length / ordersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const indexOfLastEvent = currentPage * ordersPerPage;
  const indexOfFirstEvent = indexOfLastEvent - ordersPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  return (
    <div className="orders">
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h5 className="orders-texth">Leads Orders</h5>
        </div>  
      </div>
      <div className="mt-3">
        <div className="table-responsive eventsdiv pr-4 mt-1">
          <table className="table">
            <thead>
              <tr className="tabletext">
                <th>S.No</th>
                <th>Name</th>
                <th>Event Type</th>
                <th>Start Date</th>
                <th>Client Number</th>
                <th>Venue</th>
              </tr>
            </thead>
            <tbody>
              {currentEvents.length > 0 ? (
                currentEvents.map((event, index) => (
                  <tr key={index} className="mt-3">
                    <td>{indexOfFirstEvent + index + 1}</td>
                    <td>{event.Client_Name}</td>
                    <td>{event.Event_Name}</td>
                    <td>{event.Start_Date}</td>
                    <td>{event.Client_Contact_Number}</td>
                    <td>{event.Event_Address}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6 " className="text-danger text-center">
                    No Leads Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="col-12 d-flex justify-content-end">
            <div>
              <span>
                Page {currentPage} of {totalPages}
              </span>
            </div>
            <div>
              <FontAwesomeIcon 
                onClick={handlePreviousPage} 
                className={`arrorleft-icon ${currentPage === 1 ? "disabled" : ""}`} 
                icon={faAngleLeft} 
              />
              <FontAwesomeIcon 
                onClick={handleNextPage} 
                className={`arrorright-icon ${currentPage === totalPages ? "disabled" : ""}`} 
                icon={faAngleRight} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
