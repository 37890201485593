import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import './login.css';
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;

  useEffect(() => {
    if (message) {
      toast.warn(message);
    }
  }, [message]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${config.apiBaseUrl}/user/login`, {
        email,
        password,
      });
      const { user } = response.data;
      const userResponse = await axios.get(`${config.apiBaseUrl}/user/user/${user.user_id}`);
      console.log(userResponse)
      localStorage.setItem('user', JSON.stringify(userResponse.data));
      localStorage.setItem('userId', user.user_id); 
      localStorage.setItem('username', user.username); 

      toast.success("Login successful!");

      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.error}`);
      } else {
        toast.error("Network error. Try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-fluid login-body">
      <div className="login-container">
        <div className="login-box">
          <img src={logo} alt="Kovai Caterers" className="logo" />
          <form onSubmit={handleLogin}>
            <div>
              <h6 className="reglable">Enter your E-mail</h6>
              <input
                type="email"
                className="reg-form custom-inputs"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mt-3">
              <h6 className="reglable">Enter password</h6>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  className="reg-form custom-inputs"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon className="eyepassword" icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </div>
            <button type="submit" className="login-button mt-4" disabled={isLoading}>
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </form>
          <Link to="/Sendmailotp" className="forgot-password-link">Forgot Password</Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
