import React, { useEffect, useState, useRef } from "react";
import "../style/Ingredient.css";
import config from "../config/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Ingredient() {
  const [ingredientData, setIngredientData] = useState([]); // Stores all ingredient data from the API
  const [newIngredientcard, setNewIngredientcard] = useState(false); // To toggle new ingredient form
  const [VendorTypes, setVendorTypes] = useState([]); // Stores vendor types from API
  const [selectedVendorId, setSelectedVendorId] = useState(""); // Stores the selected vendor ID

  const [newIngredient, setNewIngredient] = useState({
    ingredientName: "",
    vendorType: "",
  }); // Stores new ingredient data from the form

  const menuRef = useRef(); // For closing the new ingredient form when clicking outside

  const handelcardingrdent = () => {
    setNewIngredientcard(!newIngredientcard);
  };

  const handleinsermenuOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setNewIngredientcard(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleinsermenuOutside);
    return () => {
      document.removeEventListener("mousedown", handleinsermenuOutside);
    };
  }, []);

  // Fetch ingredients from API
  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/Get_Vendor_ingredient_details`)
      .then((response) => {
        setIngredientData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch vendor types from API
  useEffect(() => {
    const fetchVendorTypes = async () => {
      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/vendors/getVendorType`
        );
        setVendorTypes(response.data.vendor_types);
      } catch (err) {
        console.log(
          err.response?.data?.message || "An unexpected error occurred"
        );
      }
    };

    fetchVendorTypes();
  }, []);

  // Handle input changes for the new ingredient form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIngredient({ ...newIngredient, [name]: value });
  };

  // Save new ingredient
  const handleSaveIngredient = async () => {
    const { ingredientName, vendorType } = newIngredient;

    if (ingredientName && vendorType) {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/menus/Insert_Ingredientmaster`,
          {
            ingredient_name: ingredientName,
            vendor_type_id: vendorType,
          }
        );

        if (response.data) {
          const result = response.data;

          if (result.error) {
            toast.warning(`Duplicate entry: ${result.error}`);
          } else if (result.message === "Ingredient already exists!") {
            toast.error(
              "Ingredient already exists. Please use a different ingredient."
            );
          } else {
            toast.success("Ingredient created successfully.");
            setNewIngredient({ ingredientName: "", vendorType: "" });
            setNewIngredientcard(false);
          }
        }
      } catch (error) {
        console.error("Error creating ingredient:", error);
        const errorMessage =
          error.response?.data?.error || "Error creating ingredient.";
        toast.error(errorMessage);
      }
    } else {
      toast.warning("Please fill out all fields before saving.");
    }
  };

  // Handle vendor selection change
  const handleVendorSelect = (e) => {
    setSelectedVendorId(e.target.value);
  };

  return (
    <div className="menus">
      <ToastContainer />
      <h5 className="menuheadx mt-2">Ingredients</h5>
      <div className="d-flex justify-content-end">
        <button
          className="New-Create-Ingredientbtn"
          onClick={handelcardingrdent}
        >
          New Create Ingredients
        </button>
      </div>

      {newIngredientcard && (
        <div className="popup-overlay">
          <div ref={menuRef} className="menunamecreate">
            <h6 className="addingredenthead">Create Ingredients</h6>
            <div className="row p-3">
              <div className="col-6">
                <p className="ingred mt-4">Enter Ingredient Name</p>
                <input
                  type="text"
                  name="ingredientName"
                  className="form-group-input"
                  placeholder="Enter Ingredient Name"
                  value={newIngredient.ingredientName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6">
                <p className="ingred mt-4">Choose Vendor Type</p>
                <select
                  name="vendorType"
                  className="form-select"
                  value={newIngredient.vendorType}
                  onChange={handleInputChange}
                >
                  <option value="">Choose Vendor Type</option>
                  {VendorTypes.map((vendor) => (
                    <option key={vendor.Vendor_id} value={vendor.Vendor_id}>
                      {vendor.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row d-flex justify-content-center">
                <button
                  className="saves-Ingredientbtn"
                  onClick={handleSaveIngredient}
                  disabled={!newIngredient.ingredientName || !newIngredient.vendorType}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="incredenthdivchoose">
        <div className="row form-group mt-3">
          <div className="col-lg-5 col-md-7 col-sm-8 col-12 mt-1">
            <select className="form-select" onChange={handleVendorSelect}>
              <option value="" disabled>
                Ingredient Type
              </option>
              {ingredientData.map((ingredientType) => (
                <option
                  key={ingredientType.vendor_id}
                  value={ingredientType.vendor_id}
                >
                  {ingredientType.type}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12 mt-4">
            {ingredientData.length > 0 && selectedVendorId ? (
              ingredientData
                .filter(
                  (ingredientType) =>
                    ingredientType.vendor_id === parseInt(selectedVendorId)
                )
                .map((ingredientType) => (
                  <div key={ingredientType.vendor_id}>
                    <h5>{ingredientType.type}</h5>
                    <div className="row Ingredients-div">
                      {ingredientType.ingredients.map((ingredient) => (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6 col-6 p-1"
                          key={ingredient.ingredient_id}
                        >
                          <svg
                            width="13"
                            height="15"
                            className="bulleticonfixedmenu"
                            viewBox="0 0 13 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.92807 14.1218C3.79103 14.0132 3.75828 13.8184 3.85217 13.6708L7.91747 7.28189C7.98864 7.17003 7.98864 7.02712 7.91747 6.91507L3.85217 0.526192C3.75828 0.378555 3.79103 0.183978 3.92807 0.0751433C4.05242 -0.0234704 4.22826 -0.0234704 4.35262 0.0751433L12.8707 6.83084C13.0431 6.9675 13.0431 7.22927 12.8707 7.36612L4.35262 14.1218C4.22826 14.2204 4.05242 14.2204 3.92807 14.1218ZM4.08176 2.87172L0.55495 0.0747647C0.493056 0.0257418 0.41621 0 0.341634 0C0.266869 0 0.192294 0.0255526 0.1304 0.0747647C-0.00834038 0.183599 -0.0403281 0.377798 0.0535535 0.527138L4.1181 6.91507C4.19059 7.02807 4.19059 7.17116 4.1181 7.28208L0.0535535 13.6698C-0.0403281 13.8192 -0.00834038 14.0134 0.1304 14.1222C0.254188 14.2204 0.42908 14.2204 0.55495 14.1222L4.0776 11.3271L6.77026 7.09829L4.08176 2.87172Z"
                              fill="#E18722"
                            />
                          </svg>
                          {ingredient.ingredient_name}
                        </div>
                      ))}
                    </div>
                  </div>
                ))
            ) : (
              <p className="ingredients-view">
                Please select an Ingredient Type to see the ingredients.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ingredient;
