import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = Boolean(localStorage.getItem('user'));

  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" state={{ message: 'Please login in to access this page.' }} />;
};

export default PrivateRoute;
