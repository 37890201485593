import React, { useState , useEffect} from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import frontImg from '../assets/poster.png';
import config from "../config/config";

const PdfGenerator = () => {
  const [myArray, setMyArray] = useState([]);
  console.log(myArray);
  const sendForDownloadPDF = async (myArray) => {
    const response = await fetch(`${config.apiBaseUrl}/pdf/download_pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(myArray),
    });
    if (!response.ok) {
      throw new Error(`Server error: ${response.status} ${response.statusText}`);
    }
    const result = await response.json();
    console.log('Received response from /download_pdf:', result);
  };
  const generatePdf = async () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setFontSize(15);
    try {
      const response = await fetch(`${config.apiBaseUrl}/pdf/report_pdf`);
      if (!response.ok) {
        throw new Error(`Server error: ${response.status} ${response.statusText}`);
      }
      const result = await response.json();
      console.log(result);
      let tempArray = [];
      for (let i = 0; i < result.length; i++) {
        const doc = new jsPDF();
        const data = result[i].data;
        const order = result[i].order;
        const vendor = result[i].vendor || '';
        const manager = result[i].manager || '';
        const client_details = result[i].client_details;
        const imgUrl = frontImg;
        const imgWidth = 185;
        const imgHeight = 275;
        const pageWidth = doc.internal.pageSize.getWidth();
        const xPosition = (pageWidth - imgWidth) / 2;
        doc.addImage(imgUrl, 'PNG', xPosition, 10, imgWidth, imgHeight);
        let startY = 350;
        for (const category of order) {
          doc.setFontSize(15);
          const text = category;
          const paddingLeft = text !== "Overall Ingredients (Categorized)" ? 5 : 0;
          const textX = 20 + paddingLeft;
          const textY = startY;
          const textHeight = doc.getFontSize() / doc.internal.scaleFactor;
          if (text !== "Overall Ingredients (Categorized)") {
            doc.setFillColor(255, 189, 128);
            doc.rect(textX - paddingLeft, textY - textHeight - 2, pageWidth - 40, textHeight + 6, 'F');
          }
          doc.text(text, textX, textY);
          const rows = Object.entries(data[category]).map(([key, value]) => [key, value]);
          doc.autoTable({
            startY: startY + 5,
            head: [],
            body: rows,
            theme: 'plain',
            headStyles: {
              fillColor: [255, 189, 128],
              textColor: [0, 0, 0],
              lineWidth: 0.1,
              cellPadding: 4
            },
            bodyStyles: {
              textColor: [0, 0, 0],
              lineWidth: 0.1,
              cellPadding: 5
            },
            margin: { left: 20, right: 20 }
          });
          startY = doc.lastAutoTable.finalY + 10;
        }
        const pdfBlob = doc.output('blob');

        const s3Client = new S3Client({
          region: 'ap-south-1',
          credentials: {
            accessKeyId: config.apiKey,
            secretAccessKey: config.authToken
          }
        });
        const bucketName = 'kovai-caterers';
        const key = `pdf/${Date.now()}_kovaicateres_report.pdf`;
        const command = new PutObjectCommand({
          Bucket: bucketName,
          Key: key,
          ContentType: 'application/pdf',
          Body: pdfBlob,
        });
        const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
        const uploadResponse = await axios.put(signedUrl, pdfBlob, {
          headers: {
            'Content-Type': 'application/pdf'
          }
        });
        if (uploadResponse.status === 200) {
          let path = "https://kovai-caterers.s3.ap-south-1.amazonaws.com/" + key;
          console.log('PDF successfully uploaded to S3:', path);
          let objData = { "Path": path, "Vendor": vendor, "Manager": manager ,"client_details":client_details };
          tempArray.push(objData);
        } else {
          console.error('Failed to upload PDF to S3:', uploadResponse.status);
        }
      }
      setMyArray(tempArray);
      sendForDownloadPDF(tempArray);
    } catch (error) {
      console.error("Failed to generate and upload PDF:", error.message);
    }
  };
    useEffect(() => {
      const intervalId = setInterval(() => {
        generatePdf();
      }, 5000);
      return () => clearInterval(intervalId);
    });
  return (
    <div></div>
  );
};
export default PdfGenerator;