import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './forget.css';
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";

function Setpassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlesend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const otpValue = otp.join("");

    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(`${config.apiBaseUrl}/user/verifyotp&password`, { otp: otpValue, password });

      toast.success("Password updated successfully!");
      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-fluid login-body">
      <div className="login-container">
        <div className="login-box">
          <img src={logo} alt="Kovai Caterers" className="logo" />
          <form onSubmit={handlesend}>
            <div className="otp-inputs">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-field"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            <div className="mt-3">
              <h6 className="reglable">New Password</h6>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  className="reg-form custom-inputs"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon className="eyepassword" icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </div>
            <div className="mt-3">
              <h6 className="reglable">Confirm Password</h6>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  className="reg-form custom-inputs"
                  placeholder="Confirm New Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon className="eyepassword" icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </div>
            <button type="submit" className="login-button mt-4" disabled={isLoading}>
              {isLoading ? "Updating ..." : "Update"}
            </button>
          </form>
          <h6 className="backsetpassword">Back to log in? <a href="/" className="logintext-link">Login</a></h6>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Setpassword;
