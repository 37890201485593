import React, { useState, useEffect, useCallback } from "react";
import "../../style/addish.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/config.js";
import axios from "axios";
import { PulseLoader } from "react-spinners";

const EditFxMenu = ({ selectedItem, rowData, handleClose }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  let [color] = useState("#FFA500");
  const [menusCD, setMenusCD] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSelectionForEdit = useCallback(
    (data) => {
      if (!selectedItem) return;
      fetch(`${config.apiBaseUrl}/menus/get_overall_fixed_menus_items`)
        .then((response) => response.json())
        .then((data) => {
          const matchingItems = data.filter(
            (item) => item.Fixed_menu_id === rowData[0].Menu_id
          );
          const selectedMenuItems = matchingItems[0].items;
          const matchingCatodories = selectedMenuItems.filter(
            (item) => item.Category_fixed_menu_id === rowData[0].Catogory_id
          );
          const filteredMenus = matchingCatodories[0].menus;
          setMenusCD({ items: filteredMenus });
          
        const allMenuNamesSet = new Set();
        if (filteredMenus.length > 0) {
          filteredMenus.forEach(item => {
            allMenuNamesSet.add(item.childmenu_id);
          });
        }
        setSelectedFoodItems(Array.from(allMenuNamesSet)); 
        setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching menus:", error);
        });
    },
    [selectedItem, rowData]
  );
  
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  const handleCheckboxChange = (menuId) => {
    setSelectedFoodItems((prevSelected) => {
      if (prevSelected.includes(menuId)) {
        return prevSelected.filter((id) => id !== menuId);
      } else {
        return [...prevSelected, menuId];
      }
    });
  };
    

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_all_web_menu_details`)
      .then((response) => {
        setCategories(response.data);
        handleSelectionForEdit(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the data", error);
        toast.error("Error fetching menu details, please try again later.");
      });
  }, [handleSelectionForEdit]);

  const closeModal = () => {
    handleClose();
  };


  const handleInsert  = async (item) => {


    if (selectedFoodItems.length === 0) {
        toast.error("Please select at least one food item.");
        return;
    }

    const data = {
      Category_fixed_menu_id: rowData[0].Catogory_id,
      menu_ids: selectedFoodItems
  };
  

    try {
        const response = await axios.post(`${config.apiBaseUrl}/menus/Update_Category_in_fixed_menu`, data);
        toast.success(response.data.message);
        setSelectedFoodItems([]);
        setTimeout(() => {
          closeModal();
        }, 1000);
       
    } catch (err) {
        toast.error(err.response ? err.response.data.error : "An error occurred");
    }
};

  


  return (
    <>
      <div className="menus-edit">
        <ToastContainer />
        {loading ? (
          <div className="spinner-container">
            <div className="sweet-loading">
              <PulseLoader
                color={color}
                loading={loading}
                size={12}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <div
            className="modal-dialog modal-xl"
            id="EditMenuModal"
            role="document"
          >
            <div className="modal-content mt-4">
              <div className="modal-header p-2">
                <h5 className="modal-title " id="exampleModalLabel">
                  Edit Fixed Category Menu
                </h5>
              </div>
              <div className="modal-body">
                <div className="Edit-name">{selectedItem}</div>
                <div className="list-items">
                  {menusCD && menusCD.items && menusCD.items.length > 0 ? (
                    menusCD.items.map((item, index) => (
                      <div key={index} className="list-of-items">
                        <h6 className="mb-0">{item.childmenu_Name}</h6>
                      </div>
                    ))
                  ) : (
                    <div>No items available</div>
                  )}
                </div>

                <div className="row d-flex justify-content-center menu-list-cls">
                  <div>
                    {categories.map((category) => (
                      <div
                        key={category.category_Id}
                        className="col-lg-12 col-12 mt-2"
                      >
                        <div
                          className="beavergecatagry"
                          onClick={() =>
                            handleCategoryClick(category.category_Id)
                          }
                        >
                          <div className="row">
                            <div className="col-lg-11 col-md-11 col-11">
                              <h6 className="catagaryname">
                                {category.category_name}
                              </h6>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex justify-content-end chevronbutton">
                              <FontAwesomeIcon
                                className="chevronmenusicon"
                                icon={faChevronDown}
                              />
                            </div>
                          </div>
                        </div>
                        {selectedCategory === category.category_Id && (
                          <div
                            className={`row subdivfixedmenu ${
                              selectedCategory === category.category_Id
                                ? "show"
                                : ""
                            }`}
                          >
                            {category.sub_categories.map((subCategory) => (
                              <div
                                key={subCategory.sub_category_id}
                                className="sub-catagry"
                              >
                                <h5 className="sub-catagry-name">
                                  {subCategory.sub_category_name}
                                </h5>
                                <div className="row">
                                  {subCategory.menus.map((menu) => (
                                    <div
                                      key={menu.Menu_Id}
                                      className="col-lg-3 col-md-3 col-sm-4 col-6 mb-2"
                                    >
                                      <input
                                        className="form-check-input checbox"
                                        type="checkbox"
                                        checked={selectedFoodItems.includes( menu.Menu_Id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(menu.Menu_Id)
                                        }
                                      />
                                      {menu.Menu_Name}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-modal-danger"
                  onClick={() => closeModal("EditMenuModal")}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-modal-succ"
                  onClick={() => handleInsert(menusCD)}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default EditFxMenu;
